import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tag } from '../models/tag.type';
import { environment } from '../../../../environments/environment';
import { NameUrlPhotoDTO } from '../models/items';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private http: HttpClient) {
  }

  getTagById(id: number) {
    return this.http.get<Tag>(`${environment.apiBase}/tags/${id}`);
  }

  getTagsByNewsId(newsId: number){
    return this.http.get<NameUrlPhotoDTO[]>(`${environment.apiBase}/tags/byNewsId/${newsId}`);
  }

  getAllTagsNamesByNewsId(newsId: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiBase}/tags/namesByNewsId/${newsId}`);
  }
}
